import Api from '@/plugins/api';

export default {
    /** ******************************************************************************************
     * PROJECT
     ********************************************************************************************/
    /**
     * @desc Get list of the projects
     * @param params
     * @returns {Promise<*>}
     */
    async getProjects(params) {
        return (await Api.get('/projects', {
            params: {
                ...params
            }
        }));
    },
    async requestSupport(id) {
        return (await Api.post(`/projects/${id}/support-request`));
    },
    /**
     * @desc Get a project by id
     * @param project_id
     * @param params
     * @returns {Promise<*>}
     */
    async getProject(project_id, params) {
        return (await Api.get(`/projects/${project_id}?${params}`)).data.data;
    },
    /**
     * @desc Add new project: required payload: title,description,template_id
     * @param payload
     * @returns {Promise<*>}
     */
    async addProject(payload) {
        return (await Api.post('/projects', payload))
    },
    /**
     * @desc Update a specific project, payload: title, description
     * @param project_id
     * @param payload
     * @returns {Promise<*>}
     */
    async updateProject(project_id, payload) {
        return (await Api.put(`/projects/${project_id}`, payload))
    },
    /**
     * @desc delete a specific project
     * @param project_id
     * @returns {Promise<*>}
     */
    async deleteProject(project_id) {
        return (await Api.delete(`/projects/${project_id}`))
    },
    /**
     * @desc get list of templates
     * @returns {Promise<*>}
     */
    async getTemplates() {
        return (await Api.get('/templates'))
    },
    /**
     * @desc Get project notes
     * @param project_id
     * @returns {Promise<*>}
     */
    async getProjectNotes(project_id) {
        return (await Api.get(`/projects/${project_id}/notes`)).data.data
    },
    /**
     * @desc Change project template
     * @param project_id
     * @param template_id
     * @returns {Promise<*>}
     */
    async changeProjectTemplate(project_id, template_id) {
        return (await Api.post(`/projects/${project_id}/change-template`, {'template_id': template_id}))
    },
    /**
     * @desc Get project summary data
     * @param project_id
     * @param type
     * @returns {Promise<*>}
     */
    async getReport(project_id, type = 'all') {
        return (await Api.get(`/projects/${project_id}/report/${type}`)).data
    },
    /**
     * @desc Download project summary data
     * @param project_id
     * @param type
     * @returns {Promise<*>}
     */
    async downloadReport(project_id, type = 'all') {
        return (await Api.get(`/projects/${project_id}/report/${type}/download`, {responseType: 'blob'}))
    },
    /**
     * @desc Get project kpi
     * @param project_id
     * @returns {Promise<*>}
     */
    async getKpi(project_id) {
        return (await Api.get(`/projects/${project_id}/kpi`)).data
    },
    
    /**
     * @desc Send support request
     * @param project_id
     * @returns {Promise<*>}
     */
    async supportRequest(project_id) {
        return (await Api.post(`/projects/${project_id}/support-request`)).data
    },
    
    /**
     * @desc Get production calculation
     * @param project_id
     * @returns {Promise<*>}
     */
    async getProductionCalculation(project_id) {
        return (await Api.get(`/projects/${project_id}/production-calculation`)).data
    },
    
    /** ******************************************************************************************
     * PROJECT FLOOR
     ********************************************************************************************/
    /**
     * @desc Update a project floor
     * @returns {Promise<*>}
     * @param projectId
     */
    async addProjectFloor(projectId) {
        return (await Api.post('/project-floors/', {'project_id': projectId}))
    },
    /**
     * @desc Update a project floor
     * @param id
     * @param isEnabled
     * @returns {Promise<*>}
     */
    async updateProjectFloor(id, isEnabled) {
        return (await Api.put(`/project-floors/${id}`, {'is_enabled': isEnabled}))
    },
    /**
     * @desc Delete a project floor
     * @returns {Promise<*>}
     * @param id
     */
    async deleteProjectFloor(id) {
        return (await Api.delete(`/project-floors/${id}`))
    },
    /** ******************************************************************************************
     * FLOOR MODULE
     ********************************************************************************************/
    /**
     * @desc Update a floor module
     * @param id
     * @param isEnabled
     * @returns {Promise<*>}
     */
    async updateFloorModule(id, isEnabled) {
        return (await Api.put(`/floor-modules/${id}`, {'is_enabled': isEnabled}))
    },
    
    /** ******************************************************************************************
     * MODULE DESTINATION
     ********************************************************************************************/
    /**
     * @desc Update module destination
     * @param id
     * @param payload
     * @returns {Promise<*>}
     */
    async updateModuleDestination(id, payload) {
        return (await Api.put(`/module-destinations/${id}`, payload))
    },
    
    /** ******************************************************************************************
     * FLOOR NOTES
     ********************************************************************************************/
    /**
     * @desc Get floor note
     * @returns {Promise<*>}
     * @param id
     */
    async getFloorNote(id) {
        return (await Api.get(`/floor-notes/${id}`)).data.data
    },
    /**
     * @desc Create floor note
     * @returns {Promise<*>}
     * @param payload
     */
    async addFloorNote(payload) {
        return (await Api.post(`/floor-notes/`, payload))
    },
    /**
     * @desc Update floor note
     * @returns {Promise<*>}
     * @param id
     * @param text
     */
    async updateFloorNote(id, text) {
        return (await Api.put(`/floor-notes/${id}`, {'text': text}))
    },
    /**
     * @desc Delete floor note
     * @returns {Promise<*>}
     * @param payload
     */
    async deleteFloorNote(id) {
        return (await Api.delete(`/floor-notes/${id}`))
    },
    
    /** ******************************************************************************************
     * SUMMARIES
     ********************************************************************************************/
    /**
     * @desc fetch riepilogo data for a project
     * @param id
     * @returns {Promise<*>}
     */
    async getSummaries(id) {
        return (await Api.get(`/projects/${id}/summaries`)).data.data
    },
    
    /** ******************************************************************************************
     * LEGEND
     ********************************************************************************************/
    /**
     * @desc Get Legend
     * @param params
     * @returns {Promise<*>}
     */
    async getLegend(params) {
        return (await Api.get('/legend', {
            params: {
                ...params
            }
        })).data.data;
    },
    
    /**
     * Ger all users
     * @returns {Promise<*>}
     */
    async getUsers() {
        return (await Api.get('/users')).data.data;
    },
    
    /** ******************************************************************************************
     * PRODUCTION CALCULATION
     ********************************************************************************************/
    
    /**
     * @desc Update production calculation
     * @returns {Promise<*>}
     * @param id
     * @param field
     * @param value
     */
    async updateProductionCalculation(id, field, value) {
        
        return (await Api.put(`/production-calculation/${id}`, {[field]: value}))
    },
};
