import projectsApi from './projects.api';

export default {
    async requestSupport(id) {
        return await projectsApi.requestSupport(id);
    },
    /** ******************************************************************************************
     * PROJECT
     ********************************************************************************************/
    /**
     * @desc get list of projects
     * @returns {Promise<*>}
     */
    async getProjects(params) {
        return await projectsApi.getProjects(params);
    },
    /**
     * @desc get list of projects
     * @returns {Promise<*>}
     */
    async getProject(id, params = 'with=floors.modules.destinations.legend,floors.notes') {
        return await projectsApi.getProject(id, params);
    },
    /**
     * @desc Add project
     * @param payload
     * @returns {Promise<*>}
     */
    async addProject(payload) {
        return await projectsApi.addProject(payload);
    },
    /**
     * @desc update a project
     * @param project_id
     * @param payload
     * @returns {Promise<*>}
     */
    async updateProject(project_id, payload) {
        return await projectsApi.updateProject(project_id, payload);
    },
    /**
     * @desc delete a project
     * @param project_id
     * @returns {Promise<*>}
     */
    async deleteProject(project_id) {
        return await projectsApi.deleteProject(project_id);
    },
    /**
     * @desc get list of templates
     * @returns {Promise<*>}
     */
    async getTemplates() {
        return await projectsApi.getTemplates();
    },
    /**
     * @desc get project notes
     * @returns {Promise<*>}
     */
    async getProjectNotes(project_id) {
        return await projectsApi.getProjectNotes(project_id);
    },
    /**
     * @desc Change project template
     * @returns {Promise<*>}
     */
    async changeProjectTemplate(project_id, template_id) {
        return await projectsApi.changeProjectTemplate(project_id, template_id);
    },
    /**
     * @desc Get project report
     * @param project_id
     * @param type
     * @returns {Promise<*>}
     */
    async getReport(project_id, type = 'all') {
        return await projectsApi.getReport(project_id, type);
    },
    /**
     * @desc Get project report
     * @param project_id
     * @param type
     * @returns {Promise<*>}
     */
    async downloadReport(project_id, type = 'all') {
        return await projectsApi.downloadReport(project_id, type);
    },
    /**
     * @desc Get project kpi
     * @param project_id
     * @returns {Promise<*>}
     */
    async getKpi(project_id) {
        return await projectsApi.getKpi(project_id);
    },
    
    /**
     * @desc Support request
     * @param project_id
     * @returns {Promise<*>}
     */
    async supportRequest(project_id) {
        return await projectsApi.supportRequest(project_id);
    },
    
    /**
     * @desc Get production calculation
     * @param project_id
     * @returns {Promise<*>}
     */
    async getProductionCalculation(project_id) {
        return await projectsApi.getProductionCalculation(project_id);
    },
    
    /** ******************************************************************************************
     * PROJECT FLOOR
     ********************************************************************************************/
    /**
     * @desc Add project floor
     * @returns {Promise<*>}
     */
    async addProjectFloor(projectId) {
        return await projectsApi.addProjectFloor(projectId);
    },
    /**
     * @desc Update project floor
     * @returns {Promise<*>}
     */
    async updateProjectFloor(id, isEnabled) {
        return await projectsApi.updateProjectFloor(id, isEnabled);
    },
    /**
     * @desc delete a project floor
     * @returns {Promise<*>}
     * @param id
     */
    async deleteProjectFloor(id) {
        return await projectsApi.deleteProjectFloor(id);
    },
    /** ******************************************************************************************
     * FLOOR MODULE
     ********************************************************************************************/
    /**
     * @desc Update project floor
     * @returns {Promise<*>}
     */
    async updateFloorModule(id, isEnabled) {
        return await projectsApi.updateFloorModule(id, isEnabled);
    },
    /**
     * @desc Update project floor
     * @returns {Promise<*>}
     */
    /** ******************************************************************************************
     * MODULE DESTINATION
     ********************************************************************************************/
    /**
     * @desc Update module destination
     * @param id
     * @param payload
     * @returns {Promise<*>}
     */
    async updateModuleDestination(id, payload) {
        return await projectsApi.updateModuleDestination(id, payload);
    },
    /** ******************************************************************************************
     * FLOOR NOTE
     ********************************************************************************************/
    /**
     * @desc Add floor note
     * @returns {Promise<*>}
     * @param id
     */
    async getFloorNote(id) {
        return await projectsApi.getFloorNote(id);
    },
    /**
     * @desc Add floor note
     * @param payload
     * @returns {Promise<*>}
     */
    async addFloorNote(payload) {
        return await projectsApi.addFloorNote(payload);
    },
    /**
     * @desc Update floor note
     * @returns {Promise<*>}
     * @param id
     * @param text
     */
    async updateFloorNote(id, text) {
        return await projectsApi.updateFloorNote(id, text);
    },
    /**
     * @desc Delete floor note
     * @returns {Promise<*>}
     * @param id
     * @param text
     */
    async deleteFloorNote(id) {
        return await projectsApi.deleteFloorNote(id);
    },
    
    /** ******************************************************************************************
     * SUMMARIES
     ********************************************************************************************/
    
    /**
     * @desc Fetch summaries
     * @returns {Promise<*>}
     * @param projectId
     */
    async getSummaries(projectId) {
        return await projectsApi.getSummaries(projectId);
    },
    
    /** ******************************************************************************************
     * LEGEND
     ********************************************************************************************/
    /**
     * @desc Get Legend
     * @param params
     * @returns {Promise<*>}
     */
    async getLegend(params) {
        return await projectsApi.getLegend(params);
    },
    
    /**
     * @desc Get Users
     * @returns {Promise<*>}
     */
    async getUsers() {
        return await projectsApi.getUsers();
    },
    
    /** ******************************************************************************************
     * PRODUCTION CALCULATION
     ********************************************************************************************/
    
    /**
     * @desc Update production calculation
     * @returns {Promise<*>}
     * @param id
     * @param field
     * @param value
     */
    async updateProductionCalculation(id, field, value) {
        return await projectsApi.updateProductionCalculation(id, field, value);
    },
};
